


























import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";

@Component({ components: { 
        ApiButton
    } 
})

export default class BuildingIntroDialogue extends Vue {

    //
    // -- properties
    // 

    private showDialogue: boolean = false;

    //
    // -- methods
    //

    open() {
        this.showDialogue = true;
    }

    confirm() {
        this.showDialogue = false;
    }
}
