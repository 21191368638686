











































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import BuildingDialogue from "@/vue/components/BuildingDialogue.vue";
import BuildingsConfirmDialogue from "@/vue/components/BuildingsConfirmDialogue.vue";
import BuildingIntroDialogue from "@/vue/components/BuildingIntroDialogue.vue";
import BuildingLandlordUsersDialogue from "@/vue/components/BuildingLandlordUsersDialogue.vue";
import store from "@/store/store";
import { IPropertyVM } from "@/model/Api/VM/PropertyVM";
import { UserRole } from "@/model/Enums";

@Component({
    components: { 
        ApiButton,
        BuildingDialogue,
        BuildingsConfirmDialogue,
        BuildingIntroDialogue,
        BuildingLandlordUsersDialogue
    }
})
export default class Buildings extends Vue {

    async mounted() {
        this.loadBuildings();
        this.showIntroModal();
    }

    //
    // -- properties
    //

    private buildings: Array<IPropertyVM> = [];

    private get declationSubmitted() {
        return store.state.signedInUser?.declarationSubmitted;
    }

    private get isLandlordPrimary() {
        return store.state.signedInUser?.roleID == UserRole.Landlord;
    }

    //
    // -- methods
    //

    private async loadBuildings() {
        this.buildings = [];
        const response = await apiClient.get(`/api/building/LoadByLandlord?landlordID=${store.state.signedInUser?.landlordID}&userID=${store.state.signedInUser?.id}`);
        this.buildings.push(...response);
    }

    addNew() {
        const dialog: BuildingDialogue = this.$refs.buildingDialogue as BuildingDialogue;
        dialog.add();
    }

    edit(property: IPropertyVM) {
        const dialog: BuildingDialogue = this.$refs.buildingDialogue as BuildingDialogue;
        dialog.edit(property.property.id);
    }

    editUsers(property: IPropertyVM) {
        const dialog: BuildingLandlordUsersDialogue = this.$refs.buildingLandlordUsersDialogue as BuildingLandlordUsersDialogue;
        dialog.edit(property.property.id, property.property.address1);
    }

    showIntroModal() {
        if (!this.declationSubmitted) {
            const dialog: BuildingIntroDialogue = this.$refs.buildingIntroDialogue as BuildingIntroDialogue;
            dialog.open();
        }
    }

    confirmBuildings() {
        const dialog: BuildingsConfirmDialogue = this.$refs.buildingsConfirmDialogue as BuildingsConfirmDialogue;
        dialog.open();
    }
}
