




























import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
//import store from "@/store/store";
import utils from "@/utilities/Utils";
import { ISaveResponse } from "@/model/ISaveResponse";
import { IPropertyLandlordUserVM } from "@/model/Api/VM/PropertyLandlordUserVM";
import * as toastr from "toastr";

@Component({ components: { 
        ApiButton
    } 
})

export default class BuildingLandlordUsersDialogue extends Vue {

    //
    // -- properties
    //

    private showDialogue: boolean = false;
    private dialogueTitle: string = "";
    private users: Array<IPropertyLandlordUserVM> = [];
    propertyID: string = "";
    propertyDetails: string = "";
    
    private usersHeaders = [
        { text: "Assigned?", value: "userAssigned", align: "center", sortable: false },
        { text: "Name", value: "fullname", sortable: false }
    ];

    // private get incompleteVerifications() {
    //     return store.state.signedInUser?.incompleteVerifications;
    // }

    //
    // -- methods
    //
    

    async edit(id: string, propertyDetails: string) {
        this.reset();
        this.propertyID = id;
        this.propertyDetails = propertyDetails;
        await this.loadUsers();
        this.showDialogue = true;
    }

    private async loadUsers() {
        this.users = [];
        const response = await apiClient.get(`/api/building/loadPropertyLandlordUsers?id=${this.propertyID}`);
        this.users.push(...response);
    }

    private reset() {
        this.users = [];
        this.propertyID = utils.emptyGuidValue;
    }

    private async save() {
        const response: ISaveResponse = await apiClient.post("/api/building/saveBuildingUsers", this.users);
        toastr.success("Saved");
        this.$emit("reload");    
        this.showDialogue = false;
    }
}
